import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { Context, actionTypes } from '../Stores/OrderInfoStore';
import { useNavigate, useParams } from 'react-router-dom';

import { Checkmark } from '../Icons'
import { EventCard } from './EventCard'
import { Login } from '../Login'
import React from 'react';
import { countryListAlpha2 } from '../CountryList'
import { useTranslation } from 'react-i18next';

const getYears = () => {
    const currentYear = new Date().getUTCFullYear();

    return Array(currentYear - (currentYear - 99))
        .fill(currentYear)
        .map((year, index) => year - index)
}
const getMonths = () => {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
}
const getMonthDays = () => {
    return Array(31).fill(1).map((day, index) => day + index);
}

const PersonalInfoStep = ({ onNextClick, ...props}) => {
    const { t } = useTranslation();
    const [orderInfo] = React.useContext(Context);
    const { order } = orderInfo;

    const { participants } = order;
    const [participant] = participants || {};
    // const participantData = { email: '', firstName: '', lastName: '', gender: '', country: '', birthDate: { year: '', month: '', day: '' }, nationality: '', phone: { code: '', number: '' }, sportsClub: '', address: { address: '', state: '', zipCode: '', city: '', country: '' } };

    const [state, setState] = React.useState(participant)
    const handleFieldChange = (evt) => {
        const { target: { name, value } } = evt;
        const changed = { [name]: value }
        setState(prev => Object.assign({ ...prev }, changed));
    }
    const { id } = useParams();

    const handleContinueClick = () => {
        onNextClick();
    }

    const countryList = Object.keys(countryListAlpha2).map(key => { return { code: key, "name": countryListAlpha2[key] } })

    return <Grid container spacing={2}>
        <Grid item xs={12} md={8} sz={{ mx: 'auto' }}>

            <Login />

            <Paper>

                <Grid container spacing={2} sx={{ px: 2, pb: 2, mb: 6, mt: 2 }}>

                    <Grid item xs={12}>
                        <TextField
                            type="email"
                            label="Email address"
                            name="email"
                            required
                            size="small"
                            fullWidth
                            value={state.email}
                            onChange={handleFieldChange}
                            helperText="Your confirmation will be sent to this email address, please make sure it is correct."
                        />
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <TextField
                            type="text"
                            label={t("First name")}
                            size="small"
                            required
                            fullWidth
                            name="firstName"
                            onChange={handleFieldChange}
                            value={state.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="text"
                            size="small"
                            label="Last name"
                            required
                            fullWidth
                            name="lastName"
                            onChange={handleFieldChange}
                            value={state.lastName}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup row aria-label="gender" name="row-radio-buttons-group" sz={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography xs={12}>Date of Birth</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <div style={{ width: '100%' }}>
                                <TextField select value={state.birthDate.year} placeholder="Year" label="Year" size="small" fullWidth>
                                    {getYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                                </TextField>
                            </div>
                            <div style={{ width: '100%' }}>

                                <TextField select value={state.birthDate.month} placeholder="Month" label="Month" size="small" fullWidth>
                                    {getMonths().map((month, index) => <MenuItem value={index+1} key={month}>{month}</MenuItem>)}
                                </TextField>
                            </div>
                            <div style={{ width: '100%' }}>

                                <TextField select value={state.birthDate.day} placeholder="Day" label="Day" size="small" fullWidth>
                                    {getMonthDays().map(day => <MenuItem value={day} key={day}>{day}</MenuItem>)}
                                </TextField>
                            </div>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField label="Nationality" select fullWidth size="small" value={state.nationality}>
                            {countryList.map(country => <MenuItem key={country.code}>{country.name}</MenuItem>)}
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField name="sportsClub" label="Club" placeholder="Sports club name" fullWidth size="small" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField select name="size" label="Size" value={state.size} placeholder="Sports club name" size="small" fullWidth>
                            <MenuItem value="xs">XS</MenuItem>
                            <MenuItem value="sm">S</MenuItem>
                            <MenuItem value="md">M</MenuItem>
                            <MenuItem value="lg">L</MenuItem>
                            <MenuItem value="xl">XL</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ mb: 1 }}>Phone number</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField label="Code" select fullWidth size="small" value={state.country}>
                                    {countryList.sort((a, b) => a.number - b.number).map(country => <MenuItem key={country.code}>+{country.number}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField fullWidth size="small" label="Phone number" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="sportsClub" label="Club" placeholder="Sports club name" size="small" fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ mb: 1 }}>Address</Typography>

                        <TextField name="address" label="Address" placeholder="Street address" size="small" fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField name="zipCode" label="Zip code" placeholder="Zip code" size="small" fullWidth />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField name="city" label="City" placeholder="City" size="small" fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField name="state" label="State" placeholder="State" size="small" fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField label="Country" select fullWidth size="small" value={state.country}>
                            {countryList.map(country => <MenuItem key={country.code}>{country.name}</MenuItem>)}
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <Box flex justifyContent="flex-end" alignItems={'end'}>
                            <Button onClick={handleContinueClick} variant="contained">{t('continue')}</Button>
                        </Box>
                    </Grid>
                </Grid>

            </Paper>
        </Grid>
        <Grid item md={4}>

            <EventCard orderInfo={orderInfo} onNextClick={onNextClick} />
            <Typography align="center" fontWeight="bold">
                Our promise to You
            </Typography>
            <Typography align="center">
                <Checkmark /> 24h Cancellation <Checkmark /> Lowest price guaranteed <Checkmark /> Deals & Discounts
            </Typography>

        </Grid>
    </Grid>
}
export { PersonalInfoStep }
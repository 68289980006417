import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material'

import React from 'react';

const Login = () => {
    const [loginModalOpen, setLoginModalOpen] = React.useState(false);

    const handleLoginModalOpen = () => {
        setLoginModalOpen(prev => !prev);
    }
    const handleLoginModalClose = () => {
        setLoginModalOpen(prev => !prev);
    }
    return <Paper sx={{ mt: 2, p: 2, display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ flexGrow: '1' }}>Log in to autofill your personal details for a faster checkout.</Typography>
        <Button variant="contained" onClick={handleLoginModalOpen}>Login</Button>
        <Dialog open={loginModalOpen} onClose={handleLoginModalClose}>
            <DialogTitle sx={{ textAlign: 'center' }}>Welcome back!</DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <Stack spacing={2} sx={{ textAlign: 'center' }}>
                        <TextField size="small" label="Email address" fullWidth />
                        <TextField size="small" label="Password" fullWidth />
                        <Button variant="contained">Log in</Button>
                        <Typography>
                            <Link>Forgot password?</Link>
                        </Typography>
                        <Typography>
                            Don't have an account? <Link>Create one</Link>
                        </Typography>
                    </Stack>

                </DialogContentText>
            </DialogContent>
        </Dialog>
    </Paper>
}
export { Login }
import React from 'react';
const useOrderInfo = (orderId) => {
    const [loading, setLoading] = React.useState(true)

    const [data, setData] = React.useState({ order: null, event: null, customQuestions: null });

    React.useEffect(() => {
        const getData = async (url) => {
            const response = await fetch(`/.netlify/functions/${url}`);
            const json = await response.json();
            return json;
        }
        const getCustomQuestions = async (raceId) => {
            const customQuestions = await getData(`customquestions/id=${raceId}`);
            return customQuestions;
        }
        const getEventData = async (id) => {
            const event = await getData(`event?id=${id}`);
            return event;
        }
        const getOrderData = async (id) => {
            const order = await getData(`order?id=${id}`);
            return order;
        }

        setLoading(true);

        async function loadData() {
            console.log('Loading ' + orderId + '.')
            const order = await getOrderData(orderId);
            const event = await getEventData(order.marathonId)
            const customQuestions = await getCustomQuestions(order.raceId)

            setData({ order, event, customQuestions });

            setLoading(false);
        }
        console.log(`Order ID ${orderId}`)
        loadData(orderId)
    }, [orderId,])

    return [loading, data]
}
const loadOrderData = async (orderId) => {
    const getData = async (url) => {
        const response = await fetch(`/api/${url}`);
        const json = await response.json();
        return json;
    }
    const getCustomQuestions = async (id, raceId) => {
        const customQuestions = await getData(`marathons/${id}/race/${raceId}/customquestions`);
        return customQuestions;
    }
    const getEventData = async (id) => {
        const event = await getData(`marathons/${id}`);
        return event;
    }
    const getOrderData = async (id) => {
        const order = await getData(`orders/${id}`);
        return order;
    }
    const order = await getOrderData(orderId);
    const event = await getEventData(order.marathonId)
    const customQuestions = await getCustomQuestions(order.marathonId, order.raceId);

    return { order, event, customQuestions };
}
export { useOrderInfo };
export { loadOrderData };
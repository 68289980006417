import { Button, LinearProgress, Typography } from '@mui/material'
import {
    PaymentElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";

function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log('stripe changed')
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }
        console.log('getting paymentIntent ' + clientSecret);
setIsLoading(true)
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            setIsLoading(false)
            console.log(`got paymentIntent ${JSON.stringify(paymentIntent, null, 2)}`)
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "http://localhost:3000",
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {isLoading && <div>
                <Typography>Loading payment options</Typography>
                <LinearProgress color="secondary" />
            </div>}
            <PaymentElement id="payment-element" />
            {/* <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <CircularProgress /> : "Pay now"}
                </span>
            </button> */}
            {/* Show any error or success messages */}
            <Button variant="contained" sx={{mt: 2}} type="submit">Pay now</Button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}

export { CheckoutForm };
const en = {
    translation: {
        'First name': 'First name',
        'Last name': 'Last name',
        'Gender': 'Gender',
        'Male': 'Male',
        'Female': 'Female',
        continue: 'Continue'
    }
}
export default en;
import { Step, StepLabel, Stepper } from '@mui/material';

import React from 'react';
import { useLocation } from 'react-router';

const CheckoutStepper = (props) => {
    const location = useLocation();
    const [activeStep, setActiveStep] = React.useState(0);

    React.useEffect(() => {
        const { pathname } = location;
        const parts = pathname.substring(1).split('/');
        const dir = parts[2];
        const dirStepMap = {
            'book': 0,
            'payment': 1,
            'confirmation': 2
        }
        setActiveStep(dirStepMap[dir]);
    }, [location])
    return <Stepper activeStep={activeStep} color="primary" sx={{ my: 2, mt: 4 }} alternativeLabel>
        <Step active>
            <StepLabel>Personal details</StepLabel>
        </Step>
        <Step>
            <StepLabel>Payment</StepLabel>
        </Step>
        <Step>
            <StepLabel>Confirmation</StepLabel>
        </Step>
    </Stepper>
}
export { CheckoutStepper };
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';

import React from 'react';

const TermsOfService = (props) => {
    return <Grid item xs={12} sx={{ mt: 2, py: 2, px: 4 }}>
        <FormGroup>
            <FormControlLabel control={<Checkbox size="large" />} label={<Typography>I want to receive great deals, discounts and event information from World's Marathons and from the organizer. (Optional)</Typography>} />
            <FormControlLabel sx={{ pt: 2 }} control={<Checkbox size="large" />} label={<Typography>I have read and accept World's Marathons Terms of Service and Privacy Policy and consent to the processing of personal data.</Typography>} />
        </FormGroup>
    </Grid>
}
export { TermsOfService }
import { Button, CircularProgress, Paper, Typography } from '@mui/material'
import { Context, actionTypes } from '../Stores/OrderInfoStore';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadOrderData, useOrderInfo } from '../Hooks/useOrderInfo';

import { PaymentStep } from './PaymentStep';
import { PersonalInfoStep } from './PersonalInfoStep'
import React from 'react';

const CheckoutSteps = () => {
    const navigate = useNavigate();
    const [orderId, setOrderId] = React.useState();
    const [orderInfo, dispatch] = React.useContext(Context);
    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    const params = useParams();
    const handleNext = () => {
        navigate('/book/' + orderId + '/payment', { replace: false })
    }

    React.useEffect(() => {
        const loadData = (id) => {
            loadOrderData(id)
                .then(data => {
                    console.log(`Order data has been collected ${new Date().getTime()}`);
                    dispatch({ type: actionTypes.data_set, payload: data });
                    setLoading(false);
                })
        }

        function getIdFromPathname() {
            const { pathname } = location;
            const parts = pathname.substring(1).split('/');
            const [_, id] = parts;
            return id;
        }
        const id = getIdFromPathname();
        console.log({ id, orderId })
        if (id !== orderId) {
            setOrderId(prev => {
                console.log('setting orderId to ' + id);
                return id;
            })
            loadData(id);
        }
        else {
            console.log(`Location changed but orderid did not`)
        }
    }, [location]);


    return loading ? <LoadingScreen /> : <OrderRoutes onNextClick={handleNext} />
}
const LoadingScreen = () => {
    return <Paper sx={{ mt: 4, display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column', py: 10 }}>
        <CircularProgress sx={{ mx: 'auto', my: 2 }} />
        <Typography sx={{ mx: 'auto' }}>We're running 🏃🏼‍♂️ to get your order for you!</Typography>
    </Paper>
}
const OrderRoutes = ({onNextClick}) => {
    return <Routes>
        <Route path="/book/:id/info" element={<PersonalInfoStep onNextClick={onNextClick} />} />
        <Route path="/book/:id/payment" element={<PaymentStep />} />
    </Routes>
}
export { CheckoutSteps }
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, FormGroup, FormGroupLabel, Grid, Radio, TextField, Typography } from '@mui/material'
import { CheckCircle, ExpandMore, RadioButtonUnchecked } from '@mui/icons-material';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'

import { Checkmark } from '../Icons';
import { CheckoutForm } from './CheckoutForm';
import { EventCard } from './EventCard';
import React from 'react';
import { TermsOfService } from './TermsOfService';
import { loadStripe } from '@stripe/stripe-js'

const PaymentStep = () => {
    const [stripePromise, setStripePromise] = React.useState(null);
    const [stripeOptions, setStripeOptions] = React.useState(null);
    const [clientSecret, setClientSecret] = React.useState(null);

    React.useEffect(() => {
        const stripe = loadStripe('pk_test_51J9ntWKLoshtuZRUckbUfySpBemJtpfnaWDMA7Qn1f0QNZKsCWoulnhTWsn772HBVd7IWGtbU8bHsQADzoJql7X900tAT0GBht')
        console.log(stripe);
        setStripePromise(stripe);

        (async () => {
            const items = [{ id: 'xl-shirt' }];
            const response = await fetch("/.netlify/functions/payment", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ items })
            })
            const { clientSecret } = await response.json();

            setClientSecret(clientSecret);
            setStripeOptions({ clientSecret, locale: 'en', fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap' }], appearance: { labels: 'floating', variables: { borderRadius: '8px', colorPrimary: '#00D186', fontFamily: 'Inter' } } });
        })();

    }, [])

    const [expanded, setExpanded] = React.useState('creditcard');
    const handlePaymentTypeChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    return <Grid container spacing={2}>
        <Grid item xs={12} md={8} sx={{ mt: 2 }}>
            <Accordion expanded={expanded === 'creditcard'} onChange={handlePaymentTypeChange('creditcard')}>
                <AccordionSummary id="creditcard-header" expandIcon={<ExpandMore />} sx={{ alignContent: 'center' }}>

                    {expanded === 'creditcard' && <CheckCircle fontSize="large" color="primary" />}
                    {expanded !== 'creditcard' && <RadioButtonUnchecked fontSize="large" color='' />}
                    <Typography fontWeight="bold" sx={{ pl: 1, pt: 0.5 }}>Credit card</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container sx={{ px: 2 }}>
                        <Grid item xs={12}>
                            {!stripeOptions && <CircularProgress />}
                            {stripeOptions &&
                                <Elements stripe={stripePromise} options={stripeOptions}>
                                    <CheckoutForm />
                                </Elements>
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "paypal"} onChange={handlePaymentTypeChange('paypal')}>
                <AccordionSummary id="creditcard-header" expandIcon={<ExpandMore />}>
                    {expanded === 'paypal' && <CheckCircle fontSize="large" color="primary" />}
                    {expanded !== 'paypal' && <RadioButtonUnchecked fontSize="large" color='' />}
                    <Typography fontWeight="bold" sx={{ pl: 1, pt: 0.5 }}>PayPal</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" sx={{ px: 2 }}>You will be redirected to PayPal to process your payment. Your payment will be finalized after.</Typography>
                </AccordionDetails>
            </Accordion>

            <TermsOfService />

        </Grid>
        <Grid item md={4} sz={{ my: -2 }}>
            <EventCard />
            <Typography align="center" fontWeight="bold">
                Our promise to You
            </Typography>
            <Typography align="center">
                <Checkmark /> 24h Cancellation <Checkmark /> Lowest price guaranteed <Checkmark /> Deals & Discounts
            </Typography>
        </Grid>
    </Grid>
}
export { PaymentStep }
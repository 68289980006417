import { Box, Button, Drawer, Grid, IconButton, Link, Stack, Typography } from '@mui/material'
import { Close, Delete } from '@mui/icons-material';

import React from 'react';
import { makeStyles } from '@mui/styles'
import { useWindowSize } from '../Hooks/useWindowSize';

const useStyles = makeStyles({
    smallScreenBottomDrawer: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: '0px',
        height: '70px',
        backgroundColor: '#ffffff',
        zIndex: 100
    }
})
const MobileOrderTotal = () => {
    const classes = useStyles();
    const windowSize = useWindowSize();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = () => setDrawerOpen(prevState => !prevState);

    return <div className={classes.smallScreenBottomDrawer}>
        <Grid container flex alignItems="center" alignContent="center" height="100%">
            <Grid item xs={4} sx={{ pl: 2 }}>
                <Typography variant="body2">1 Participant</Typography>
                <Typography variant="body2">
                    <Link>Add more</Link>
                </Typography>
            </Grid>
            <Grid item xs={4} >
                <Typography variant="body1"><Link onClick={toggleDrawer}>Show details</Link></Typography>
                <Typography variang="h5" fontWeight="bold">85 EUR</Typography>
            </Grid>
            <Grid item xs={4} textAlign="right" sx={{ pr: 2 }}>
                <Button variant="contained">Continue</Button>
            </Grid>
        </Grid>
        <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer}>
            <Grid container flex spacing={2} sx={{ p: 0 }}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', p: 2 }}>
                        <Typography sx={{ flexGrow: 1, pt: 1 }} variant="h4" fontWeight="bold">Order details ({windowSize.width})</Typography>
                        <IconButton onClick={toggleDrawer}><Close /></IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: "#efefef", p: 2 }}>
                    <Stack sx={{ pt: 2, pl: 2, pb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ flexGrow: 1 }} variant="body1">Marathon</Typography>
                            <Typography fontWeight="bold">88.39 EUR</Typography>
                            <IconButton size="small"><Delete /></IconButton>
                        </Box>
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ p: 2, flexGrow: 1 }} variant="body1" fontWeight="bold">Total</Typography>
                        <Typography sx={{ p: 1 }} variant="body1" fontWeight="bold">88.59 EUR</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Drawer>
    </div>
}

export { MobileOrderTotal }
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material"

const EventCard = ({onNextClick, orderInfo}) => {
    return <Card sx={{ my: 2 }}>
        <CardMedia
            component="img"
            image="https://wmimg.azureedge.net/public/img/marathons/kathmandu-urban-marathon/kathmandu-urban-marathon_500.jpg" height={200}></CardMedia>
        <CardContent>
            <Typography variant="h4" fontWeight="bold">Your order</Typography>
            <Typography>{orderInfo?.order.marathonName}</Typography>
            <Typography variant="h5" fontWeight="bold"></Typography>

        </CardContent>
        <CardActions sx={{ p: 2 }}>
            <Button variant="contained" fullWidth onClick={onNextClick}>Continue</Button>
        </CardActions>
    </Card>
}
export { EventCard };
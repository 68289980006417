import "./App.css";

import {
  Container,
  CssBaseline,
} from "@mui/material"

import { AuthContext } from './Contexts/AuthContext';
import { CheckoutStepper } from './Components/CheckoutStepper';
import { CheckoutSteps } from "./Components/CheckoutSteps";
import { DesktopHeader } from './Components/DesktopHeader'
import { MobileHeader } from "./Components/MobileHeader";
import { MobileOrderTotal } from './Components/MobileOrderTotal'
import MuiThemeProvider from '@mui/material/styles/ThemeProvider'
import OrderInfoStore from "./Stores/OrderInfoStore";
import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useWindowSize } from './Hooks/useWindowSize'
import wsgTheme from "./WorldSportsGroupTheme";

function App() {
  const { t } = useTranslation();
  const [checkoutStep, setCheckoutStep] = React.useState(0);
  const windowSize = useWindowSize();

  const currentUser = localStorage.getItem('currentUser');
  let existingLogin = null;
  if (currentUser && currentUser !== 'null' && currentUser !== 'undefined')
    existingLogin = JSON.parse(currentUser);

  const [loginData, setLoginData] = React.useState(existingLogin);
  const setLogin = (data) => {
    if (data) localStorage.setItem('currentUser', JSON.stringify(data));
    else localStorage.removeItem('currentUser');
    setLoginData(data);
  };

  let loggedIn = Date.now() < loginData?.expiry;
  if (!loggedIn) localStorage.removeItem('currentUser');

  React.useEffect(() => { }, [windowSize])

  return (
    <MuiThemeProvider theme={wsgTheme}>
      <CssBaseline />
      <AuthContext.Provider
        value={{ loginData, setLoginData: setLogin }}
      >
        <OrderInfoStore initialState={{ order: {}, loading: true }}>
          {windowSize.width < 768 && <MobileHeader />}
          {windowSize.width >= 768 && <DesktopHeader />}
          <Container maxWidth={"md"}>
            <Router>
                <CheckoutStepper currentStep={checkoutStep} />
                <CheckoutSteps />
                {/* <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/book/:id/info" element={<PersonalInfoStep />} />
                  <Route path="/book/:id/payment" element={<PaymentStep />} />
                </Routes> */}
            </Router>
          </Container>
          {windowSize.width < 768 && <MobileOrderTotal />}
        </OrderInfoStore>
      </AuthContext.Provider>
    </MuiThemeProvider>
  );
}

export default App;

import { AccountCircle, Language } from '@mui/icons-material'
import { Grid, IconButton, Stack, Typography } from '@mui/material'

import React from 'react';

const MobileHeader = () => {
    return <Grid container sx={{ padding: 2, backgroundColor: '#fff' }}>
        <Grid item xs={9}>
            <Typography fontWeight="bold">Kathmandu Urban Marathon</Typography>
            <Typography variant="body2">22 March 2022</Typography>
        </Grid>
        <Grid item xs={3} justifyContent="end">
            <Stack direction="row">
            <IconButton aria-label="">
                <AccountCircle />
            </IconButton>
            <IconButton aria-label="">
                <Language />
            </IconButton>
            </Stack>
        </Grid>
    </Grid>
}
export { MobileHeader }
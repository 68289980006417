import LanguageDetector from 'i18next-browser-languagedetector';
import en from './Resources/en.js'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    },
    resources: {
        en
    }
})
export default i18n;
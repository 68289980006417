import { CurrencyExchange, LanguageOutlined, SquareFoot, Straighten } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';

import Logo from '../logo.svg';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { wmPalette as palette } from "../WorldSportsGroupTheme";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            // margin: theme.spacing(2),
        },
    },
    toolBar: {
        background: palette.black[90],
        color: palette.black[10],
        // padding: theme.spacing(1),
        // marginBottom: theme.spacing(4),
    }
}))

const DesktopHeader = () => {
    const classes = useStyles();
    return <div className={classes.toolBar}>

        <div style={{ "display": "flex", justifyContent: 'space-evenly', alignItems: 'center' }}>
            <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
                <LanguageOutlined sx={{ mr: 1 }} /><Typography variant="body2">Language: <strong>EN</strong></Typography>

                <CurrencyExchange sx={{ mx: 1 }} /> <Typography variant="body2">Currency: <strong>EUR</strong></Typography>

                <SquareFoot sx={{ mx: 1 }} /> <Typography variant="body2">Measures <strong>Metric</strong></Typography>
            </div>
            <div style={{ flexGrow: 0, textAlign: 'center' }}>
                <img src={Logo} alt="" height={24} style={{ marginBottom: 8, marginTop: 16 }} />
            </div>
            <div style={{ padding: 8, paddingRight: 16 }}><Typography><Link>Sign up</Link> <Link>Log in</Link></Typography></div>
        </div>

    </div>
}
export { DesktopHeader }
import { Container, Dialog, DialogContent, Fab, TextField, TextareaAutosize } from '@mui/material';
import React, { createContext, useReducer } from 'react';

import { BugReport } from '@mui/icons-material';

const actionTypes = {
    data_set: 'SET_DATA',
    order_set: 'SET_ORDER',
    event_set: 'SET_EVENT',
    customQuestions_set: 'SET_CUSTOMQUESTIONS',
    loading_set: 'SET_LOADING'
};

const handlers = {
    [actionTypes.order_set]: (state, action) => {
        const order = action.payload;
        return {
            order
        };
    },
    [actionTypes.event_set]: (state, action) => {
        const event = action.payload;
        return {
            event
        };
    },
    [actionTypes.customQuestions_set]: (state, action) => {
        const customQuestions = action.payload;
        return {
            customQuestions
        };
    },
    [actionTypes.loading_set]: (state, action) => {
        return { loading: action.payload };
    },
    [actionTypes.data_set]: (state, action) => {
        const { order, event, customQuestions } = action.payload;
        return { order, event, customQuestions, loading: false }
    }
}

const OrderInfoReducer = (state, action) => {
    console.log(`[OrderInfoReducer] running ${action.type}`)
    const handler = handlers[action.type];
    if (!handler) {
        throw new Error(`Unknown action type: ${action.type}`)
    }
    const nextState = handler(state, action);

    const newState = Object.assign({ ...state, ...nextState });

    return newState;
}
export const Context = createContext();
const OrderInfoStore = ({ initialState, children }) => {
    const [state, dispatch] = useReducer(OrderInfoReducer, initialState);
    return (<Context.Provider value={[state, dispatch]}>
        {children}
        <OrderInfoFab state={state} />
    </Context.Provider>)
}
const OrderInfoFab = ({ state }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    return <>
        <Fab onClick={() => setDialogOpen(true)} color="secondary" sx={{ position: 'absolute', right: 16, bottom: 16 }}>
            <BugReport />
        </Fab>
        <Dialog onClose={() => { setDialogOpen(false); }} open={dialogOpen} fullWidth>
            <DialogContent>
                <TextField fullWidth multiline value={JSON.stringify(state, null, 2)} />
            </DialogContent>
        </Dialog>
    </>
}
export { actionTypes };
export default OrderInfoStore;